const listTrainingProgram = {
  "Terminale BAC PRO Procédés de la Chimie et de l’Eau – Lycée Vauquelin": {
    "niveau": "BAC",
    "ufa1": "Lycée Louis Nicolas Vauquelin",
    "logo1": "https://afi24cv.com/images/logos_ufa/vauquelin.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "40022004",
    "codeRNCP": "RNCP38499",
    "nomformation": "Terminale BAC PRO Procédés de la Chimie et de l’Eau – Lycée Vauquelin",
    "ufa3": "",
    "logo3": ""
  },
  "Formation Exemple": {
    "nomformation": "Formation Exemple",
    "ufa1": "Etablissement diplômant",
    "logo1": "https://afi24cv.com/images/logos_ufa/logo.jpg",
    "ufa2": "Etablissement partenaire 2",
    "logo2": "https://afi24cv.com/images/logos_ufa/logo.jpg",
    "ufa3": "Etablissement partenaire 3",
    "logo3": "https://afi24cv.com/images/logos_ufa/logo.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "niveau": "BAC + 5",
    "codeDiplome": "17011201",
    "codeRNCP": "RNCP29329"
  },
  "2ème année BTS Bio Analyses et Contrôles – ETSL": {
    "niveau": "BAC+2",
    "ufa1": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo1": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022207",
    "codeRNCP": "RNCP2705",
    "nomformation": "2ème année BTS Bio Analyses et Contrôles – ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Bio Analyses et Contrôles – Lycée Valentine Labbé": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Valentine Labbé",
    "logo1": "https://afi24cv.com/images/logos_ufa/labbe.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022207",
    "codeRNCP": "RNCP2705",
    "nomformation": "2ème année BTS Bio Analyses et Contrôles – Lycée Valentine Labbé",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Contrôle Industriel et Régulation Automatique – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020113",
    "codeRNCP": "RNCP38216",
    "nomformation": "2ème année BTS Contrôle Industriel et Régulation Automatique – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie – ETSL": {
    "niveau": "BAC+2",
    "ufa1": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo1": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie – ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie – Lycée Gustave Eiffel": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Gustave Eiffel",
    "logo1": "https://afi24cv.com/images/logos_ufa/eiffel.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie – Lycée Gustave Eiffel",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie – Lycée Galilée": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Galilée",
    "logo1": "https://afi24cv.com/images/logos_ufa/galilee.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie – Lycée Galilée",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de l’Eau – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020114",
    "codeRNCP": "RNCP37964",
    "nomformation": "2ème année BTS Métiers de l’Eau – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Pilotage de Procédés – Lycée Vauquelin": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Louis Nicolas Vauquelin",
    "logo1": "https://afi24cv.com/images/logos_ufa/vauquelin.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022003",
    "codeRNCP": "RNCP38217",
    "nomformation": "2ème année BTS Pilotage de Procédés – Lycée Vauquelin",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la mesures – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022002",
    "codeRNCP": "RNCP35803",
    "nomformation": "2ème année BTS Métiers de la mesures – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Bio Analyses et Contrôles – ETSL": {
    "niveau": "BAC+2",
    "ufa1": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo1": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022207",
    "codeRNCP": "RNCP2705",
    "nomformation": "BTS Bio Analyses et Contrôles – ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Bio Analyses et Contrôles – Lycée Valentine Labbé": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Valentine Labbé",
    "logo1": "https://afi24cv.com/images/logos_ufa/labbe.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022207",
    "codeRNCP": "RNCP2705",
    "nomformation": "BTS Bio Analyses et Contrôles – Lycée Valentine Labbé",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Contrôle Industriel et Régulation Automatique – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020113",
    "codeRNCP": "RNCP38216",
    "nomformation": "BTS Contrôle Industriel et Régulation Automatique – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie – ETSL": {
    "niveau": "BAC+2",
    "ufa1": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo1": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie – ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie – Lycée Gustave Eiffel": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Gustave Eiffel",
    "logo1": "https://afi24cv.com/images/logos_ufa/eiffel.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie – Lycée Gustave Eiffel",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie – Lycée Galilée": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Galilée",
    "logo1": "https://afi24cv.com/images/logos_ufa/galilee.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie – Lycée Galilée",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de l’Eau – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020114",
    "codeRNCP": "RNCP37964",
    "nomformation": "BTS Métiers de l’Eau – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Mesure – ENCPB": {
    "niveau": "BAC+2",
    "ufa1": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022002",
    "codeRNCP": "RNCP35803",
    "nomformation": "BTS Métiers de la Mesure – ENCPB",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Mesure – Lycée Gustave Eiffel": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Gustave Eiffel",
    "logo1": "https://afi24cv.com/images/logos_ufa/eiffel.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022002",
    "codeRNCP": "RNCP35803",
    "nomformation": "BTS Métiers de la Mesure – Lycée Gustave Eiffel",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Pilotage de Procédés – Lycée de L’Escaut": {
    "niveau": "BAC+2",
    "ufa1": "Lycée de l'Escaut",
    "logo1": "https://afi24cv.com/images/logos_ufa/escaut.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022003",
    "codeRNCP": "RNCP38217",
    "nomformation": "BTS Pilotage de Procédés – Lycée de L’Escaut",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Pilotage de Procédés – Lycée Vauquelin": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Louis Nicolas Vauquelin",
    "logo1": "https://afi24cv.com/images/logos_ufa/vauquelin.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022003",
    "codeRNCP": "RNCP38217",
    "nomformation": "BTS Pilotage de Procédés – Lycée Vauquelin",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours Analyse, contrôle qualité, environnement - IUT d'ORSAY": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours Analyse, contrôle qualité, environnement - IUT d'ORSAY",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT  Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122001",
    "codeRNCP": "RNCP35405",
    "nomformation": "2ème et 3ème année BUT  Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "Bachelor Chimie et Sciences Industrielles - ESCOM": {
    "niveau": "BAC+3",
    "ufa1": "ESCOM Chimie",
    "logo1": "https://afi24cv.com/images/logos_ufa/escom.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26511601",
    "codeRNCP": "RNCP35785",
    "nomformation": "Bachelor Chimie et Sciences Industrielles - ESCOM",
    "ufa3": "",
    "logo3": ""
  },
  "BUT Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122001",
    "codeRNCP": "RNCP35405",
    "nomformation": "BUT Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Assurance Qualité Industrielle - Université Paris Cité - ETSL": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Cité (Faculté de Pharmacie de Paris)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris-pharma.jpg",
    "ufa2": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo2": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25022281",
    "codeRNCP": "RNCP30075",
    "nomformation": "Licence Pro Assurance Qualité Industrielle - Université Paris Cité - ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Biotechnologie - Université Paris Cité - ETSL": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Cité (Faculté de Pharmacie de Paris)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris-pharma.jpg",
    "ufa2": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo2": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25022281",
    "codeRNCP": "RNCP30075",
    "nomformation": "Licence Pro Biotechnologie - Université Paris Cité - ETSL",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Analyse, contrôle qualité, environnement - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "3ème année BUT Chimie Parcours Analyse, contrôle qualité, environnement - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours  Analyse, contrôle qualité, environnement - IUT Orléans": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Orléans - IUT d'Orléans",
    "logo1": "https://afi24cv.com/images/logos_ufa/orleans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "3ème année BUT Chimie Parcours  Analyse, contrôle qualité, environnement - IUT Orléans",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Contrôle et développement analytique - Université Paris Cité": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Cité (Faculté de Pharmacie de Paris)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris-pharma.jpg",
    "ufa2": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo2": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25022281",
    "codeRNCP": "RNCP30075",
    "nomformation": "Licence Pro Contrôle et développement analytique - Université Paris Cité",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Chimie Formulation - Sorbonne Université": {
    "niveau": "BAC+3",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo2": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25022284",
    "codeRNCP": "RNCP30050",
    "nomformation": "Licence Pro Chimie Formulation - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Chimie : Formulation, Parcours Formulation industrielle - CY Tech": {
    "niveau": "BAC+3",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25022284",
    "codeRNCP": "RNCP30050",
    "nomformation": "Licence Pro Chimie : Formulation, Parcours Formulation industrielle - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Synthèse - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122204",
    "codeRNCP": "RNCP35495",
    "nomformation": "3ème année BUT Chimie Parcours Synthèse - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Chimie et Physique des Matériaux - Sorbonne Université": {
    "niveau": "BAC+3",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo2": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25011602",
    "codeRNCP": "RNCP30053",
    "nomformation": "Licence Pro Chimie et Physique des Matériaux - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Orléans": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Orléans - IUT d'Orléans",
    "logo1": "https://afi24cv.com/images/logos_ufa/orleans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Orléans",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Génomique - CNAM": {
    "niveau": "BAC+3",
    "ufa1": "Conservatoire national des arts et métiers",
    "logo1": "https://afi24cv.com/images/logos_ufa/cnam.jpg",
    "ufa2": "ENCPB - Lycée Pierre Gilles de Gennes",
    "logo2": "https://afi24cv.com/images/logos_ufa/encpb.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25011202",
    "codeRNCP": "RNCP30047",
    "nomformation": "Licence Pro Génomique - CNAM",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Analyse Chimique (LIPAC) - Université Paris Cité": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Cité (UFR des Sciences -Chimie)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25011601",
    "codeRNCP": "RNCP30051",
    "nomformation": "Licence Pro Analyse Chimique (LIPAC) - Université Paris Cité",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Analyse contrôle-qualité et environnement - IUT Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "3ème année BUT Chimie Parcours Analyse contrôle-qualité et environnement - IUT Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Chimie Industrielle - IUT Orléans": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Orléans - IUT d'Orléans",
    "logo1": "https://afi24cv.com/images/logos_ufa/orleans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122202",
    "codeRNCP": "RNCP35497",
    "nomformation": "3ème année BUT Chimie Parcours Chimie Industrielle - IUT Orléans",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Qualité, Hygiène, Santé, Sécurité, Environnement - IUT Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "2502001U",
    "codeRNCP": "RNCP30098",
    "nomformation": "Licence Pro Qualité, Hygiène, Santé, Sécurité, Environnement - IUT Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Qualité Hygiène Santé Sécurité Environnement QHSSE - Parcours à Dominante Santé Sécurité Environnement (SSE) - CY Yech": {
    "niveau": "BAC+3",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "ETSL - Ecole Technique Supérieure du Laboratoire",
    "logo2": "https://afi24cv.com/images/logos_ufa/etsl.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "2502001U",
    "codeRNCP": "RNCP30098",
    "nomformation": "Licence Pro Qualité Hygiène Santé Sécurité Environnement QHSSE - Parcours à Dominante Santé Sécurité Environnement (SSE) - CY Yech",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Qualité, Hygiène, Sécurité, Santé, Environnement (QHSSE)- Parcours Management des Risques Technologiques et Professionnels - IUT de Sénart": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Sénart Fontainebleau (Site Moissy-Cramayel)",
    "logo1": "https://afi24cv.com/images/logos_ufa/senart.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "2502001U",
    "codeRNCP": "RNCP30098",
    "nomformation": "Licence Pro Qualité, Hygiène, Sécurité, Santé, Environnement (QHSSE)- Parcours Management des Risques Technologiques et Professionnels - IUT de Sénart",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Traitement et Analyse de l’Eau et des Déchets Aqueux - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25034011",
    "codeRNCP": "RNCP30087",
    "nomformation": "Licence Pro Traitement et Analyse de l’Eau et des Déchets Aqueux - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "Ingénieur de l’École supérieure de chimie organique et minérale- ESCOM": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "ESCOM Chimie",
    "logo1": "https://afi24cv.com/images/logos_ufa/escom.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "1701160A",
    "codeRNCP": "RNCP16165",
    "nomformation": "Ingénieur de l’École supérieure de chimie organique et minérale- ESCOM",
    "ufa3": "",
    "logo3": ""
  },
  "Ingénieur CY Tech / Spécialité Informatique - CY Tech": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "17032619",
    "codeRNCP": "RNCP36424",
    "nomformation": "Ingénieur CY Tech / Spécialité Informatique - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "Ingénieur CY Tech / Spécialité Mathématiques appliquées - CY Tech": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "17011402",
    "codeRNCP": "RNCP36556",
    "nomformation": "Ingénieur CY Tech / Spécialité Mathématiques appliquées - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "4ème année Ingénieur de l’École supérieure de chimie organique et minérale - ESCOM": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "ESCOM Chimie",
    "logo1": "https://afi24cv.com/images/logos_ufa/escom.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "1701160A",
    "codeRNCP": "RNCP16165",
    "nomformation": "4ème année Ingénieur de l’École supérieure de chimie organique et minérale - ESCOM",
    "ufa3": "",
    "logo3": ""
  },
  "4ème et 5ème année Ingénieur CY Tech / Spécialité Informatique - CY Tech": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "17032619",
    "codeRNCP": "RNCP36424",
    "nomformation": "4ème et 5ème année Ingénieur CY Tech / Spécialité Informatique - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "4ème et 5ème année Ingénieur CY Tech / Spécialité Mathématiques appliquées - CY Tech": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "17011402",
    "codeRNCP": "RNCP36556",
    "nomformation": "4ème et 5ème année Ingénieur CY Tech / Spécialité Mathématiques appliquées - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie - Domaine Chimie Analytique, Physique et Théorique - Sorbonne Université": {
    "niveau": "BAC+5",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie - Domaine Chimie Analytique, Physique et Théorique - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie - Domaine Chimie Moléculaire - Sorbonne Université": {
    "niveau": "BAC+5",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie - Domaine Chimie Moléculaire - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie - Domaine Ingénierie Chimique - Sorbonne Université": {
    "niveau": "BAC+5",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie - Domaine Ingénierie Chimique - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie - Domaine Chimie des Matériaux - Sorbonne Université": {
    "niveau": "BAC+5",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie - Domaine Chimie des Matériaux - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie Contrôle et Qualité - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie Contrôle et Qualité - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie et Sciences des Matériaux - Evry": {
    "niveau": "BAC+5",
    "ufa1": "Université D'Evry",
    "logo1": "https://afi24cv.com/images/logos_ufa/evry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13522012",
    "codeRNCP": "RNCP34112",
    "nomformation": "2ème année Master Chimie et Sciences des Matériaux - Evry",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie - Ingénierie Technico-Commerciale - ITC - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Chimie - Ingénierie Technico-Commerciale - ITC - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Risques et environnement – Atmosphères Intérieure et extéRieure AIR - UPEC": {
    "niveau": "BAC+5",
    "ufa1": "Université Paris Est Créteil",
    "logo1": "https://afi24cv.com/images/logos_ufa/upec.jpg",
    "ufa2": "Université Paris Cité (UFR des Sciences -Chimie)",
    "logo2": "https://afi24cv.com/images/logos_ufa/paris.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13534335",
    "codeRNCP": "RNCP34070",
    "nomformation": "2ème année Master Risques et environnement – Atmosphères Intérieure et extéRieure AIR - UPEC",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Risques et environnement : Management de l’Environnement des Collectivités et des Entreprises MECE - UPEC": {
    "niveau": "BAC+5",
    "ufa1": "Université Paris Est Créteil",
    "logo1": "https://afi24cv.com/images/logos_ufa/upec.jpg",
    "ufa2": "Université Paris Cité (UFR des Sciences -Chimie)",
    "logo2": "https://afi24cv.com/images/logos_ufa/paris.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13534335",
    "codeRNCP": "RNCP34070",
    "nomformation": "2ème année Master Risques et environnement : Management de l’Environnement des Collectivités et des Entreprises MECE - UPEC",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Risques et environnement : Systèmes Aquatiques et Gestion de l’Eau SAGE - Université Paris Cité": {
    "niveau": "BAC+5",
    "ufa1": "Université Paris Cité (UFR des Sciences -Chimie)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris.jpg",
    "ufa2": "Université Paris Est Créteil",
    "logo2": "https://afi24cv.com/images/logos_ufa/upec.jpg",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13534335",
    "codeRNCP": "RNCP34070",
    "nomformation": "2ème année Master Risques et environnement : Systèmes Aquatiques et Gestion de l’Eau SAGE - Université Paris Cité",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Marketing et Santé - UVSQ": {
    "niveau": "BAC+5",
    "ufa1": "Université Versailles Saint-Quentin-en-Yvelines",
    "logo1": "https://afi24cv.com/images/logos_ufa/uvsq.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13533121",
    "codeRNCP": "RNCP34295",
    "nomformation": "2ème année Master Marketing et Santé - UVSQ",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master STP Environnement (Sciences de la terre et des Planètes, Environnement) : Parcours Eco-conception et Gestion des Déchets - ECOGED - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511710",
    "codeRNCP": "RNCP31500",
    "nomformation": "2ème année Master STP Environnement (Sciences de la terre et des Planètes, Environnement) : Parcours Eco-conception et Gestion des Déchets - ECOGED - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master STP Environnement (Sciences de la Terre et des Planètes, Environnement) : Parcours Ecoconstruction - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511710",
    "codeRNCP": "RNCP31500",
    "nomformation": "2ème année Master STP Environnement (Sciences de la Terre et des Planètes, Environnement) : Parcours Ecoconstruction - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master STP Environnement (Sciences de la terre et des Planètes, Environnement): Parcours Responsabilité Sociétale de l'Entreprise, Communication et Environnement - RSE-Com - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511710",
    "codeRNCP": "RNCP31500",
    "nomformation": "2ème année Master STP Environnement (Sciences de la terre et des Planètes, Environnement): Parcours Responsabilité Sociétale de l'Entreprise, Communication et Environnement - RSE-Com - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "Master Chimie - Analyse et Assurance Qualité - AAQ - UPEC": {
    "niveau": "BAC+5",
    "ufa1": "Université Paris Est Créteil",
    "logo1": "https://afi24cv.com/images/logos_ufa/upec.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "Master Chimie - Analyse et Assurance Qualité - AAQ - UPEC",
    "ufa3": "",
    "logo3": ""
  },
  "Master Chimie et Sciences des Matériaux - Evry": {
    "niveau": "BAC+5",
    "ufa1": "Université D'Evry",
    "logo1": "https://afi24cv.com/images/logos_ufa/evry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13522012",
    "codeRNCP": "RNCP34112",
    "nomformation": "Master Chimie et Sciences des Matériaux - Evry",
    "ufa3": "",
    "logo3": ""
  },
  "Master Formulation et Data Mining - FDM - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "Master Formulation et Data Mining - FDM - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "Expert en environnement et développement durable - Mines Paris - PSL": {
    "niveau": "BAC+6",
    "ufa1": "Mines Paris-PSL",
    "logo1": "https://afi24cv.com/images/logos_ufa/mines.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "16X31033",
    "codeRNCP": "RNCP34196",
    "nomformation": "Expert en environnement et développement durable - Mines Paris - PSL",
    "ufa3": "",
    "logo3": ""
  },
  "Expert en prévention des risques et en gestion des crises dans l'industrie - Mines Paris - PSL": {
    "niveau": "BAC+6",
    "ufa1": "Mines Paris-PSL",
    "logo1": "https://afi24cv.com/images/logos_ufa/mines.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "16X31032",
    "codeRNCP": "RNCP36225",
    "nomformation": "Expert en prévention des risques et en gestion des crises dans l'industrie - Mines Paris - PSL",
    "ufa3": "",
    "logo3": ""
  },
  "Expert en Ingénierie et Gestion du Gaz - Mines Paris - PSL": {
    "niveau": "BAC+6",
    "ufa1": "Mines Paris-PSL",
    "logo1": "https://afi24cv.com/images/logos_ufa/mines.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "16X22702",
    "codeRNCP": "RNCP32220",
    "nomformation": "Expert en Ingénierie et Gestion du Gaz - Mines Paris - PSL",
    "ufa3": "",
    "logo3": ""
  },
  "Expert en optimisation des systèmes énergétiques - Mines Paris - PSL": {
    "niveau": "BAC+6",
    "ufa1": "Mines Paris-PSL",
    "logo1": "https://afi24cv.com/images/logos_ufa/mines.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "16X22701",
    "codeRNCP": "RNCP35272",
    "nomformation": "Expert en optimisation des systèmes énergétiques - Mines Paris - PSL",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Mesure – Lycée Gustave Eiffel": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Gustave Eiffel",
    "logo1": "https://afi24cv.com/images/logos_ufa/eiffel.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022002",
    "codeRNCP": "RNCP35803",
    "nomformation": "2ème année BTS Métiers de la Mesure – Lycée Gustave Eiffel",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Pilotage de Procédés – Lycée de L’Escaut": {
    "niveau": "BAC+2",
    "ufa1": "Lycée de l'Escaut",
    "logo1": "https://afi24cv.com/images/logos_ufa/escaut.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022003",
    "codeRNCP": "RNCP38217",
    "nomformation": "2ème année BTS Pilotage de Procédés – Lycée de L’Escaut",
    "ufa3": "",
    "logo3": ""
  },
  "Master Chimie - Parcours Contrôle et Qualité - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "Master Chimie - Parcours Contrôle et Qualité - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Formulation et Data Mining - CY Tech": {
    "niveau": "BAC+5",
    "ufa1": "CY Cergy Paris Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/cy.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "2ème année Master Formulation et Data Mining - CY Tech",
    "ufa3": "",
    "logo3": ""
  },
  "Master Chimie - Sorbonne Université": {
    "niveau": "BAC+5",
    "ufa1": "Sorbonne Université",
    "logo1": "https://afi24cv.com/images/logos_ufa/sorbonne.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13511614",
    "codeRNCP": "RNCP31803",
    "nomformation": "Master Chimie - Sorbonne Université",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours Analyse, contrôle-qualité, environnement – IUT Créteil Vitry": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Créteil-Vitry",
    "logo1": "https://afi24cv.com/images/logos_ufa/creteil-vitry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours Analyse, contrôle-qualité, environnement – IUT Créteil Vitry",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Créteil Vitry": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Créteil-Vitry",
    "logo1": "https://afi24cv.com/images/logos_ufa/creteil-vitry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Créteil Vitry",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Génie Biologique Parcours Sciences de l'environnement et écotechnologies - IUT de Sénart Fontainebleau": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Sénart Fontainebleau (Site Moissy-Cramayel)",
    "logo1": "https://afi24cv.com/images/logos_ufa/senart.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25134301",
    "codeRNCP": "RNCP35370",
    "nomformation": "2ème et 3ème année BUT Génie Biologique Parcours Sciences de l'environnement et écotechnologies - IUT de Sénart Fontainebleau",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours synthèse - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122204",
    "codeRNCP": "RNCP35495",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours synthèse - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours Chimie Industrielle - Procédés et Bio-procédés - IUT Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122202",
    "codeRNCP": "RNCP35497",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours Chimie Industrielle - Procédés et Bio-procédés - IUT Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année Master Chimie et Sciences des Matériaux - Tours": {
    "niveau": "BAC+5",
    "ufa1": "Université de Tours - UFR de Sciences et Techniques",
    "logo1": "https://afi24cv.com/images/logos_ufa/tours.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13522012",
    "codeRNCP": "RNCP34112",
    "nomformation": "2ème année Master Chimie et Sciences des Matériaux - Tours",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie Intelligence Artificielle au service des procédés chimiques - Lycée Paul Constans": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Polyvalent Paul Constans",
    "logo1": "https://afi24cv.com/images/logos_ufa/paul-constans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie Intelligence Artificielle au service des procédés chimiques - Lycée Paul Constans",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie - IUT de Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "2ème et 3ème année BUT Chimie - IUT de Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "BUT Chimie - IUT de Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "BUT Chimie - IUT de Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Chimie Industrielle - Procédés et Bio-procédés - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122202",
    "codeRNCP": "RNCP35497",
    "nomformation": "3ème année BUT Chimie Parcours Chimie Industrielle - Procédés et Bio-procédés - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Métiers de la Chimie - Lycée Notre Dame Chartres": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Notre Dame Chartres",
    "logo1": "https://afi24cv.com/images/logos_ufa/chartres.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "BTS Métiers de la Chimie - Lycée Notre Dame Chartres",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122001",
    "codeRNCP": "RNCP35405",
    "nomformation": "3ème année BUT Science et Génie des Matériaux : Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Analyse, contrôle-qualité, environnement – IUT Créteil Vitry": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Créteil-Vitry",
    "logo1": "https://afi24cv.com/images/logos_ufa/creteil-vitry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122201",
    "codeRNCP": "RNCP35494",
    "nomformation": "3ème année BUT Chimie Parcours Analyse, contrôle-qualité, environnement – IUT Créteil Vitry",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Créteil Vitry": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Créteil-Vitry",
    "logo1": "https://afi24cv.com/images/logos_ufa/creteil-vitry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "3ème année BUT Chimie Parcours Matériaux et produits formulés – IUT Créteil Vitry",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie - Lycée Paul Constans": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Polyvalent Paul Constans",
    "logo1": "https://afi24cv.com/images/logos_ufa/paul-constans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie - Lycée Paul Constans",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Génie Biologique Option Sciences de l'environnement et écotechnologies – IUT de Sénart Fontainebleau": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Est Créteil - IUT de Sénart Fontainebleau (Site Moissy-Cramayel)",
    "logo1": "https://afi24cv.com/images/logos_ufa/senart.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25134301",
    "codeRNCP": "RNCP35370",
    "nomformation": "3ème année BUT Génie Biologique Option Sciences de l'environnement et écotechnologies – IUT de Sénart Fontainebleau",
    "ufa3": "",
    "logo3": ""
  },
  "Licence Pro Analyse des Matériaux - ANAMAT - Université Paris Cité Faculté de Physique": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Cité (Faculté de Physique)",
    "logo1": "https://afi24cv.com/images/logos_ufa/paris-physique.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25011602",
    "codeRNCP": "RNCP30053",
    "nomformation": "Licence Pro Analyse des Matériaux - ANAMAT - Université Paris Cité Faculté de Physique",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Chimie industrielle - IUT Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122202",
    "codeRNCP": "RNCP35497",
    "nomformation": "3ème année BUT Chimie Parcours Chimie industrielle - IUT Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Matériaux et produits formulés - IUT Béthune": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Artois - IUT de Béthune",
    "logo1": "https://afi24cv.com/images/logos_ufa/bethune.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "3ème année BUT Chimie Parcours Matériaux et produits formulés - IUT Béthune",
    "ufa3": "",
    "logo3": ""
  },
  "BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122002",
    "codeRNCP": "RNCP35404",
    "nomformation": "BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122002",
    "codeRNCP": "RNCP35404",
    "nomformation": "3ème année BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris 13 - IUT de Saint-Denis",
    "logo1": "https://afi24cv.com/images/logos_ufa/saint-denis.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122001",
    "codeRNCP": "RNCP35404",
    "nomformation": "2ème et 3ème année BUT Science et Génie des Matériaux : Métiers de l'ingénierie des matériaux et des produits - IUT St. Denis",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Métiers de la Chimie - Lycée Notre Dame de Chartres": {
    "niveau": "BAC+2",
    "ufa1": "Lycée Notre Dame Chartres",
    "logo1": "https://afi24cv.com/images/logos_ufa/chartres.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32022208",
    "codeRNCP": "RNCP38382",
    "nomformation": "2ème année BTS Métiers de la Chimie - Lycée Notre Dame de Chartres",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Synthèse - IUT Orléans": {
    "niveau": "BAC+3",
    "ufa1": "Université d'Orléans - IUT d'Orléans",
    "logo1": "https://afi24cv.com/images/logos_ufa/orleans.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122204",
    "codeRNCP": "RNCP35495",
    "nomformation": "3ème année BUT Chimie Parcours Synthèse - IUT Orléans",
    "ufa3": "",
    "logo3": ""
  },
  "BTS Contrôle Industriel et Régulation Automatique - Lycée de l'Escaut": {
    "niveau": "BAC+2",
    "ufa1": "Lycée de l'Escaut",
    "logo1": "https://afi24cv.com/images/logos_ufa/escaut.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020113",
    "codeRNCP": "RNCP38216",
    "nomformation": "BTS Contrôle Industriel et Régulation Automatique - Lycée de l'Escaut",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Science et Génie des Matériaux - Métiers de l’ingénierie des matériaux et des produits -  IUT Evry": {
    "niveau": "BAC+3",
    "ufa1": "IUT D'Evry",
    "logo1": "https://afi24cv.com/images/logos_ufa/iut-evry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122002",
    "codeRNCP": "RNCP35404",
    "nomformation": "3ème année BUT Science et Génie des Matériaux - Métiers de l’ingénierie des matériaux et des produits -  IUT Evry",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Science et Génie des Matériaux - Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT Evry": {
    "niveau": "BAC+3",
    "ufa1": "IUT D'Evry",
    "logo1": "https://afi24cv.com/images/logos_ufa/iut-evry.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122001",
    "codeRNCP": "RNCP35405",
    "nomformation": "3ème année BUT Science et Génie des Matériaux - Métiers de la caractérisation et de l’expertise des matériaux et des produits - IUT Evry",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année Bachelor Responsable du Développement Commercial - ESG Toulouse": {
    "niveau": "BAC+3",
    "ufa1": "Ecole de Commerce ESG Toulouse",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26X31250",
    "codeRNCP": "RNCP36610",
    "nomformation": "3ème année Bachelor Responsable du Développement Commercial - ESG Toulouse",
    "ufa3": "",
    "logo3": ""
  },
  "Expert en Management Industriel et Systèmes Logistiques - Mines Paris - PSL": {
    "niveau": "BAC+6",
    "ufa1": "Mines Paris-PSL",
    "logo1": "https://afi24cv.com/images/logos_ufa/mines.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "A venir",
    "codeRNCP": "RNCP38103",
    "nomformation": "Expert en Management Industriel et Systèmes Logistiques - Mines Paris - PSL",
    "ufa3": "",
    "logo3": ""
  },
  "2ème année BTS Contrôle Industriel et Régulation Automatique CIRA - Lycée de l'Escaut": {
    "niveau": "BAC+2",
    "ufa1": "Lycée de l'Escaut",
    "logo1": "https://afi24cv.com/images/logos_ufa/escaut.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "32020113",
    "codeRNCP": "RNCP38216",
    "nomformation": "2ème année BTS Contrôle Industriel et Régulation Automatique CIRA - Lycée de l'Escaut",
    "ufa3": "",
    "logo3": ""
  },
  "Ingénieur chimiste de l’École Nationale Supérieure de Chimie de Mulhouse - ENSCMu": {
    "niveau": "BAC+5|Ingénieur",
    "ufa1": "Ecole Nationale Supérieure de Chimie de Mulhouse",
    "logo1": "https://afi24cv.com/images/logos_ufa/enscmu.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "-",
    "codeRNCP": "RNCP24282",
    "nomformation": "Ingénieur chimiste de l’École Nationale Supérieure de Chimie de Mulhouse - ENSCMu",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Chimie Parcours Matériaux et produits formulés - Matériaux inorganiques - IUT d'ORSAY": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "3ème année BUT Chimie Parcours Matériaux et produits formulés - Matériaux inorganiques - IUT d'ORSAY",
    "ufa3": "",
    "logo3": ""
  },
  "2ème et 3ème année BUT Chimie Parcours Matériaux et produits formulés - Matériaux inorganiques - IUT d'Orsay": {
    "niveau": "BAC+3",
    "ufa1": "Université Paris Saclay - IUT d'Orsay",
    "logo1": "https://afi24cv.com/images/logos_ufa/orsay.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122203",
    "codeRNCP": "RNCP35496",
    "nomformation": "2ème et 3ème année BUT Chimie Parcours Matériaux et produits formulés - Matériaux inorganiques - IUT d'Orsay",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année BUT Génie Chimique-Génie des Procédés : Conception des Procédés et Innovation technologique - IUT de Rambouillet - UVSQ": {
    "niveau": "BAC+3",
    "ufa1": "IUT Vélizy Rambouillet - UVSQ",
    "logo1": "https://afi24cv.com/images/logos_ufa/uvsq-rambouillet.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "25122205",
    "codeRNCP": "RNCP35373",
    "nomformation": "3ème année BUT Génie Chimique-Génie des Procédés : Conception des Procédés et Innovation technologique - IUT de Rambouillet - UVSQ",
    "ufa3": "",
    "logo3": ""
  },
  "Master Chimie et Sciences des Matériaux - Tours": {
    "niveau": "BAC+5",
    "ufa1": "Université de Tours - UFR de Sciences et Techniques",
    "logo1": "https://afi24cv.com/images/logos_ufa/tours.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "13522012",
    "codeRNCP": "RNCP34112",
    "nomformation": "Master Chimie et Sciences des Matériaux - Tours",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année Bachelor Responsable du Développement Commercial - ESG Strasbourg": {
    "niveau": "BAC+3",
    "ufa1": "Ecole de Commerce ESG Strasbourg",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26X31250",
    "codeRNCP": "RNCP36610",
    "nomformation": "3ème année Bachelor Responsable du Développement Commercial - ESG Strasbourg",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année Bachelor Responsable du Développement Commercial - ESG Lyon": {
    "niveau": "BAC+3",
    "ufa1": "Ecole de Commerce ESG Lyon",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "ufa3": "",
    "logo3": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26X31250",
    "codeRNCP": "RNCP36610",
    "nomformation": "3ème année Bachelor Responsable du Développement Commercial - ESG Lyon"
  },
  "3ème année Bachelor Responsable du Développement Commercial - ESG Rennes": {
    "niveau": "BAC+3",
    "ufa1": "Ecole de Commerce ESG Rennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26X31250",
    "codeRNCP": "RNCP36610",
    "nomformation": "3ème année Bachelor Responsable du Développement Commercial - ESG Rennes",
    "ufa3": "",
    "logo3": ""
  },
  "3ème année Bachelor Responsable du Développement Commercial - ESG Paris": {
    "niveau": "BAC+3",
    "ufa1": "Ecole de Commerce ESG Paris ",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "26X31250",
    "codeRNCP": "RNCP36610",
    "nomformation": "3ème année Bachelor Responsable du Développement Commercial - ESG Paris",
    "ufa3": "",
    "logo3": ""
  },
  "Mastère Manager de la Logistique - Supply Chain Manager - ESG Paris": {
    "niveau": "BAC+5",
    "ufa1": "Ecole de Commerce ESG Paris ",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "",
    "codeRNCP": "",
    "nomformation": "Mastère Manager de la Logistique - Supply Chain Manager - ESG Paris",
    "ufa3": "",
    "logo3": ""
  },
  "Mastère Manager de la Logistique - Supply Chain Manager - ESG Toulouse": {
    "niveau": "BAC+5",
    "ufa1": "Ecole de Commerce ESG Toulouse",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "",
    "codeRNCP": "",
    "nomformation": "Mastère Manager de la Logistique - Supply Chain Manager - ESG Toulouse",
    "ufa3": "",
    "logo3": ""
  },
  "Mastère Manager de la Logistique - Supply Chain Manager - ESG Rennes": {
    "niveau": "BAC+5",
    "ufa1": "Ecole de Commerce ESG Rennes",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "",
    "codeRNCP": "",
    "nomformation": "Mastère Manager de la Logistique - Supply Chain Manager - ESG Rennes",
    "ufa3": "",
    "logo3": ""
  },
  "Mastère Manager de la Logistique - Supply Chain Manager - ESG Strasbourg": {
    "niveau": "BAC+5",
    "ufa1": "Ecole de Commerce ESG Strasbourg",
    "logo1": "https://afi24cv.com/images/logos_ufa/esg.jpg",
    "ufa2": "",
    "logo2": "",
    "logoAFi24": "https://afi24cv.com/images/logos_ufa/afi24.jpg",
    "codeDiplome": "",
    "codeRNCP": "",
    "nomformation": "Mastère Manager de la Logistique - Supply Chain Manager - ESG Strasbourg",
    "ufa3": "",
    "logo3": ""
  }
}

export default listTrainingProgram;