import { navigate } from '@reach/router';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import React, { createContext, memo, useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import useAuthState from '../hooks/useAuthState';

const defaultUser = {
  uid: null,
  email: null,
  displayName: null,
  isAnonymous: false,
  address:null,
  phone: null,
  birthDate: null,
  drivingLicence: false,
  mobility: null,
};

const defaultState = {
  loading: false,
  user: defaultUser,
  logout: async () => {},
  loginWithGoogle: async () => {},
  loginWithFocaliz: async () => {},
  loginAnonymously: async () => {},
  deleteAccount: async () => {},
};

const UserContext = createContext(defaultState);

const UserProvider = ({ children }) => {
  const [firebaseUser, loading] = useAuthState(firebase);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    setUser(localUser);
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      const remoteUser = pick(firebaseUser, Object.keys(defaultUser));
      const userRef = firebase.database().ref(`users/${remoteUser.uid}`);
      localStorage.setItem('user', JSON.stringify(remoteUser));
      setUser(remoteUser);

      userRef.once('value').then((snapshot) => {
        if (snapshot.exists()) {
          localStorage.setItem('user', JSON.stringify(snapshot.val()));
          setUser(snapshot.val());
        } else {
          console.log("No data available");
          userRef.set(remoteUser);
        }
      });

    }
  }, [firebaseUser]);

  const handleResponse = (response) => {

      return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (response.ok) {
        if ([401, 403, 404].indexOf(data.code) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api

          const error = (data && data.msg) || response.status;
          return Promise.reject(error);
        } else return data;

      }
      else {
        logout();
        location.reload(true);
        const error = (data && data.msg) || response.status;
        console.log('erreur :', error)
        return Promise.reject(error);
      }
    });
  }


  const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      return await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loginAnonymously = async () => {
    try {
      return await firebase.auth().signInAnonymously();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loginWithFocaliz = async ({username, password}) => {

    let formData = new FormData();
    formData.append('key', '2ff54ce4113da9f559723eab83790e92');
    formData.append('secret', '8cbd286a68ba388ec0be2d465866fcda');
    formData.append('username', `${username}`);
    formData.append('password', `${password}`);

    const requestOptions = {
      method: 'POST',
      body: formData
    };

    try {

      // return await fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
      return await fetch('https://afi24.v6.focaliz.net/api/cvlogin/', requestOptions)
        .then(handleResponse)
        .then (
          userData => {
            firebase.auth().signInWithEmailAndPassword(username, password)
              .then ( () => {
                // Signed in
                const userId = firebase.auth().currentUser.uid;
                firebase.database().ref('/users/' + userId).once('value').then((snapshot) => {
                  if (snapshot.exists()) {
                    localStorage.setItem('user', JSON.stringify(snapshot.val()));
                    setUser(snapshot.val());
                  } else {
                    console.log("No data available");
                  }
                })
              })
              .catch(function (error) {
                if (error.code === 'auth/user-not-found') {
                  firebase.auth().createUserWithEmailAndPassword(username, password)
                    .then ( (userCredential)  => {
                      var user = pick(userCredential.user, Object.keys(defaultUser));
                      firebase
                        .database()
                        .ref(`users/${user.uid}`)
                        .update({
                          ...user,
                          displayName: userData.data.displayName,
                          address:{
                            city: userData.data.address.city,
                            addressLine1: userData.data.address.addressLine1,
                            addressLine2: userData.data.address.addressLine2,
                            pinCode: userData.data.address.pinCode,
                          },
                          email: userData.data.email,
                          phone: userData.data.phone,
                          birthDate: userData.data.birthDate,
                          drivingLicence: userData.data.drivingLicence,
                          mobility: userData.data.mobility,
                        })

                    })
                    .then(function () {
                      const userId = firebase.auth().currentUser.uid;
                      firebase.database().ref('/users/' + userId).once('value').then((snapshot) => {
                        if (snapshot.exists()) {
                          localStorage.setItem('user', JSON.stringify(snapshot.val()));
                          setUser(snapshot.val());
                        } else {
                          console.log("No data available");
                        }
                      }).catch((error) => {
                        console.error(error);
                      });


                    })
                    .catch(function (error) {
                      error.message.replace(".", "");
                      alert(error.message + " (" + error.code + ")");
                    });
                }
              })
          })
    }
    catch (error) {
      toast.error(error);
    }
  };

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.removeItem('user');
    setUser(null);
    navigate('/');
  };

  const reauthenticateWithGoogle = async () => {
    const { currentUser } = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const userCredential = await currentUser.reauthenticateWithPopup(
        provider,
      );
      return userCredential;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const reauthenticate = async () => {
    const { currentUser } = firebase.auth();

    if (currentUser.isAnonymous) {
      return;
    }

    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    const authProviderIsGoogle =
      currentUser.providerData &&
      currentUser.providerData.length > 0 &&
      currentUser.providerData[0].providerId === googleAuthProvider.providerId;

    if (authProviderIsGoogle) {
      await reauthenticateWithGoogle();
    } else {
      const errorMessage = 'Unable to determine reauthentication method.';
      toast.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  const deleteAccount = async () => {
    const { currentUser } = firebase.auth();
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

   // await reauthenticate();

    await deleteUser();

    try {
      await currentUser.delete();
    } catch (error) {
      toast.error(error.message);
    } finally {
      await logout();
      toast(
        "Triste de vous voir partir,  mais nous respectons vos choix. Toutes vos données ont été effacées avec succès.Au plaisir de vous revoir rapidement !",
      );
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        loading,
        loginWithGoogle,
        loginWithFocaliz,
        loginAnonymously,
        deleteAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

const memoizedProvider = memo(UserProvider);

export { memoizedProvider as UserProvider };