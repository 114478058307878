import { debounce } from 'lodash';
import React, { createContext, memo, useContext, useState } from 'react';
import ShortUniqueId from 'short-unique-id';
import firebase from 'gatsby-plugin-firebase';
import UserContext from './UserContext';
import { getUnsplashPhoto } from '../utils';
import initialState from '../data/initialState.json';

const DEBOUNCE_WAIT_TIME = 4000;

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  getResumes: async () => {},
  updateResume: async () => {},
  debouncedUpdateResume: async () => {},
};

const DatabaseContext = createContext(defaultState);

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });

  const [isUpdating, setUpdating] = useState(false);
  const [isFromFocaliz, setFromFocaliz] = useState(false);
  const { user } = useContext(UserContext);

  const getResume = async (id) => {
    try {
      const snapshot = await firebase
        .database()
        .ref(`resumes/${id}`)
        .once('value');
      return snapshot.val();
    } catch (error) {
      return null;
    }
  };

  const createResume = async ({ name }) => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    let firstName;
    let lastName;
    let addressCity;
    let addressLine1;
    let addressLine2;
    let addressPinCode;
    let profilPhone;
    let profilBirthDate;
    let profilDrivingLicence;
    let profilMobility;
    let fromFocaliz = user.uid;

    if ((!user.isAnonymous) && (user.displayName)) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    const getFromFocaliz = async () => {
      try {
        await firebase
          .database()
          .ref(`users/${fromFocaliz}/address/`)
          .once("value", snapshot => {
            if (snapshot.exists()) {
              addressCity = user.address.city;
              addressLine1 = user.address.addressLine1;
              addressLine2 = user.address. addressLine2;
              addressPinCode = user.address.pinCode;
              profilPhone = user.phone;
              profilBirthDate = user.birthDate;
              profilDrivingLicence = user.drivingLicence;
              profilMobility = user.mobility;
            }
          })
      } catch (error) {
        console.log("existe pas de catch error !");
        return false;
      }
    };
    await getFromFocaliz();

    const resume = {
      ...initialState,
      id,
      name,
      user: user.uid,
      preview,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
        address:  {
          city: addressCity || '',
          line1: addressLine1 || '',
          line2: addressLine2 || '',
          pincode: addressPinCode || '',
        } ,
        phone: profilPhone || '',
        birthDate: profilBirthDate || '',
        drivingLicence: profilDrivingLicence || false,
        mobility: profilMobility || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);

  };

  const duplicateResume = async (originalResume) => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = firebase.database.ServerValue.TIMESTAMP;

    const resume = {
      ...originalResume,
      id,
      name: `Copie de ${originalResume.name}`,
      preview,
      createdAt,
      updatedAt: createdAt,
    };

    firebase.database().ref(`resumes/${id}`).set(resume);
  };

  const updateResume = async (resume) => {
    setUpdating(true);

    await firebase
      .database()
      .ref(`resumes/${resume.id}`)
      .update({
        ...resume,
        updatedAt: firebase.database.ServerValue.TIMESTAMP,
      });

    setUpdating(false);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    await firebase.database().ref(`/resumes/${id}`).remove();
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        createResume,
        duplicateResume,
        updateResume,
        deleteResume,
        debouncedUpdateResume,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
