import React, { createContext, memo, useEffect, useState } from 'react';
import i18next from 'i18next';
import themeConfig from '../data/themeConfig';
import listTrainingProgram from "../data/listTrainingProgram-SAVE-2024";


const languageStorageItemKey = 'language';

const defaultState = {
  theme: 'Dark',
  setTheme: () => {},
  language: 'fr',
  setLanguage: () => {},
  trainingProgram: {
    "nomformation": "Intitulé exact de la formation exemple",
  } ,
  setTrainingProgram: () => {},
  isSideBarOpen: true,
  setSideBarToggle: () => {},
};

const SettingsContext = createContext(defaultState);

const SettingsProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultState.theme);
  const [language, setLanguage] = useState(defaultState.language);
  const [trainingProgram, setTrainingProgram] = useState(defaultState.trainingProgram.nomformation);
  const [isSideBarOpen, setSideBarToggle] = useState(defaultState.isSideBarOpen);

  useEffect(() => {
    const prefTheme = localStorage.getItem('theme') || defaultState.theme;
    const prefTrainingProgram = localStorage.getItem('trainingProgram') || defaultState.trainingProgram.nomformation;
    const prefLanguage =
      localStorage.getItem(languageStorageItemKey) || defaultState.language;
    setTheme(prefTheme);
    setLanguage(prefLanguage);
    setTrainingProgram(prefTrainingProgram);
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
    const colorConfig = themeConfig[theme];
    for (const [key, value] of Object.entries(colorConfig)) {
      document.documentElement.style.setProperty(key,value);
    }
  }, [theme]);

  useEffect(() => {
    localStorage.setItem(languageStorageItemKey, language);
    i18next.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    localStorage.setItem('trainingProgram',listTrainingProgram);
    //changeTrainingProgram(listTrainingProgram);
  }, [listTrainingProgram]);

  return (
    <SettingsContext.Provider
      value={{
        theme,
        setTheme,
        language,
        setLanguage,
        trainingProgram,
        setTrainingProgram,
        isSideBarOpen,
        setSideBarToggle,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

const memoizedProvider = memo(SettingsProvider);

export { memoizedProvider as SettingsProvider, languageStorageItemKey };
